import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useVesselSummaryMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useVesselSummaryMaintenanceVM";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { memo } from "react";
import { HPHBreadcrumb } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const VesselSummaryTitleBar: React.FC = () => {
    const VSL_SUMMARY_CONSTANT = ChargeConstant.VesselSummary;
    const vslSummaryVM = useVesselSummaryMaintenanceVM();


    return <Sidebarheader style={{ width: '100%' }}>
        <HeaderWithBackButton callback={vslSummaryVM.onCloseClick}>
            <Breadcrumb>
                <HPHBreadcrumb breadcrumbData={[{ title: ChargeConstant.ChargeHeader.CHARGE_PROCESSING }, { title: VSL_SUMMARY_CONSTANT.VESSEL_SUMMARY }]} onCurrentClick={() => { }}></HPHBreadcrumb>
            </Breadcrumb>
        </HeaderWithBackButton>
    </Sidebarheader>

}

export default memo(VesselSummaryTitleBar);