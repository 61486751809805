import type { CustomCellRendererProps } from "ag-grid-react";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent } from "helpers/Events";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithStringColorAndDocIcon } from "presentation/view/components/TableWrapper/TableCellRender";

const IB_CNTR_SUMMARY_CONSTANT = ChargeConstant.InboundContainerSummary;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];

export const INITIAL_INBOUND_CONTAINER_SUMMARY_HEADER_COL_DEF: any[] = [
    {
        headerName: IB_CNTR_SUMMARY_CONSTANT.DELIVERY_STATUS,
        field: 'deliverStatus',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: IB_CNTR_SUMMARY_CONSTANT.CONTAINER_NO,
        field: 'cntrNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: IB_CNTR_SUMMARY_CONSTANT.STATUS,
        field: 'cntrStatus',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
    },
    {
        headerName: IB_CNTR_SUMMARY_CONSTANT.SIZE,
        field: 'cntrSize',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
    },
    {
        headerName: IB_CNTR_SUMMARY_CONSTANT.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: IB_CNTR_SUMMARY_CONSTANT.DS_DATETIME,
        field: 'dischargeDatetime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
        dataType: "date",
    },
    {
        headerName: IB_CNTR_SUMMARY_CONSTANT.DELIVERY_DATETIME,
        field: 'deliverDatetime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
        dataType: "date",
    },
    {
        headerName: IB_CNTR_SUMMARY_CONSTANT.END_VISIT_IND,
        field: 'outTerminalInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: IB_CNTR_SUMMARY_CONSTANT.END_VISIT_DATE,
        field: 'outTmlDatetime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
        dataType: "date",
    },
    {
        headerName: IB_CNTR_SUMMARY_CONSTANT.HANDLING_TML,
        field: 'handlingTerminal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: IB_CNTR_SUMMARY_CONSTANT.COKD_OPSDATE,
        field: 'cokdOpsDatetime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 300,
        dataType: "dateTime",
    },
    {
        headerName: IB_CNTR_SUMMARY_CONSTANT.STATUS_COKD,
        field: 'cokdStatus',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    cellRenderers["cntrNo"] = cellRenderWithStringColorAndDocIcon;

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[], chargeHdrId: number | null) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    let newData = data?.map(row => ({
        ...row,
        chargeHdrId: chargeHdrId
    }));

    externalFnctions["cntrNo"] = calculatFieldIcon;

    return transferRowDataInternal(newData, dateFieldList, dateTimeFieldList, [], externalFnctions);
}

export const calculatFieldIcon = (fieldName: string, row: any) => {
    let result: { [key: string]: any } = {};
    if (fieldName && row) {
        const className = fieldName + "Class";
        const toolTipName = fieldName + "Tooltip";
        const listenerName = fieldName + "Listener";
        const fieldValue: number = (row[fieldName] as unknown as number);
        result[fieldName] = fieldValue;

        if (fieldValue) {
            result[className] = "";
            result[toolTipName] = ChargeConstant.VesselSummary.DETAIL;
            result[listenerName] = () => {
                customDispatchEvent(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT,
                    E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY,
                    {
                        data:
                        {
                            ...row,
                            redirectType: "inbound_Summary_Column",
                            fieldName: fieldName
                        }
                    }
                )
            }
        }
    }
    return result;
}

