import { INITIAL_INBOUND_CONTAINER_SUMMARY_HEADER_COL_DEF, transferRowData } from "presentation/constant/ChargeHeader/InboundContainerSummary/InboundContainerSummaryColumnDefinition";
import { useInboundContainerSummaryMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useInboundContainerSummaryMaintenanceVM";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useInboundContainerSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/InboundContainerSummaryMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useCallback, useMemo } from "react";


export const InboundContainerSummaryTablePanel: React.FC = () => {
    const [ibCntrSummaryState] = useInboundContainerSummaryMaintenanceTracked();
    const [chargeHeaderState] = useChargeHeaderMaintenanceTracked();
    const vslSummaryVM = useInboundContainerSummaryMaintenanceVM();

    const tbBtns = useMemo(() => {
        let btns = [];
        btns.push({
            id: 'onSearch',
            icon: 'Icon-search',
            title: 'Search'
        });
        return btns;
    }, [])

    const handleSearchClick = useCallback(() => {
        vslSummaryVM.onSearchClick();
    }, [vslSummaryVM]);

    const memoGroupTable = useMemo(() => {
        return (
            <NbisTable
                id={'Inbound Container Summary Table'}
                isNewColumnSetting={true}
                // headerLabel={IB_CNTR_SUMMARY_CONSTANT.INBOUND_CONTAINER_SUMMARY}
                columns={INITIAL_INBOUND_CONTAINER_SUMMARY_HEADER_COL_DEF}
                data={transferRowData(ibCntrSummaryState.inboundContainerSummaryDataList, (chargeHeaderState?.currentChargeHeader?.id ?? 0))}
                headerActionButtons={tbBtns}
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                isRowHighligted={true}
                gridHeight="customHeight"
                customHeight="calc(100vh - 125px)"
                onSearch={handleSearchClick}
            />
        );
    }, [ibCntrSummaryState.inboundContainerSummaryDataList, chargeHeaderState?.currentChargeHeader?.id, tbBtns, handleSearchClick])

    return <TableWrapper>{memoGroupTable}</TableWrapper>
}


