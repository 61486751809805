import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useInboundContainerSummaryMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useInboundContainerSummaryMaintenanceVM";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useInboundContainerSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/InboundContainerSummaryMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateTimePickerComponent from "presentation/view/components/DateTimePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useMemo, useState } from "react";
import { FieldType, GroupCheckboxList, HPHButton, HPHGroupCheckbox, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const InboundContainerSummarySearchCriteriaPanel = () => {    
    const IB_CNTR_SUMMARY_CONSTANT = ChargeConstant.InboundContainerSummary;
    const ibCntrSummaryVM = useInboundContainerSummaryMaintenanceVM();
    const [inCntrSumState] = useInboundContainerSummaryMaintenanceTracked();
    const [chargeHdrState] = useChargeHeaderMaintenanceTracked();
    const editingHdr = chargeHdrState.currentChargeHeader;
    const ibCntrSearchCriteria = inCntrSumState.searchCriteria;
    const CHARGEDETAIL_CONSTANT = ChargeDetailConstant.Charge;
    const [isLoading, setIsLoading] = useState<boolean>(false);    
    const CHARGE_CONSTANT = ChargeConstant.Charge;

    const onSearchClicked = async() => {
        if (!editingHdr) return;
        setIsLoading(true);

        await ibCntrSummaryVM.onSearch(editingHdr,ibCntrSearchCriteria.deliveryStatusList).then(data => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
    }

    const memoVslType = useMemo(() =>
        <div className='flex-row-item'>
            <NbisCommonField
                isReadOnly={true}
                readOnlyValue={inCntrSumState.vesselType??""}
                fieldLabel={IB_CNTR_SUMMARY_CONSTANT.VESSEL_TYPE}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                isMultipleDropDown={true}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [IB_CNTR_SUMMARY_CONSTANT.VESSEL_TYPE, inCntrSumState.vesselType])    

    const memoConsVslVoy = useMemo(() => {
        return <>
        <div className='flex-row-item'>
            <NbisCommonField
                isReadOnly={true}
                readOnlyValue={`${editingHdr?.consortiumCode}/
                    ${editingHdr?.vesselCode}/
                    ${editingHdr?.voyageCode}`
                }
                fieldLabel={CHARGE_CONSTANT.CO_VSL_VOY}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                isMultipleDropDown={true}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
        </>
    }, [CHARGE_CONSTANT.CO_VSL_VOY, editingHdr?.consortiumCode, editingHdr?.vesselCode, editingHdr?.voyageCode]);

    
    const memoEtd = useMemo(() =>
        <div className='flex-row-item'>
            <DateTimePickerComponent
                disabled={true}
                date={editingHdr?.etd}
                label={IB_CNTR_SUMMARY_CONSTANT.ETD}
                onDateChange={()=>{}}
                />
        </div>
    , [IB_CNTR_SUMMARY_CONSTANT.ETD, editingHdr?.etd])

    const memoHandlingTml = useMemo(() =>
        <div className='flex-row-item'>
            <NbisCommonField
                isReadOnly={true}
                readOnlyValue={editingHdr?.handlingTerminal??""}
                fieldLabel={IB_CNTR_SUMMARY_CONSTANT.HANDLING_TML}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                isMultipleDropDown={true}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [IB_CNTR_SUMMARY_CONSTANT.HANDLING_TML, editingHdr?.handlingTerminal])

    const memoDeliveryStatus = useMemo(() =>
        <div className='flex-row-item'>
            <HPHGroupCheckbox
                label={IB_CNTR_SUMMARY_CONSTANT.DELIVERY_STATUS}
                checkboxData={[
                    {key:"Delivered", name:"Delivered", disabled:false},
                    {key:"In Yard", name:"In Yard", disabled:false},
                ]}
                selectedValues={ibCntrSearchCriteria.deliveryStatusList}
                orientation={"horizontal"}
                onChange={(e: (GroupCheckboxList | undefined)[]) => ibCntrSummaryVM.onGroupCheckboxChange(e, ibCntrSearchCriteria, "deliveryStatusList")}
            />
        </div>
    , [IB_CNTR_SUMMARY_CONSTANT.DELIVERY_STATUS, ibCntrSearchCriteria, ibCntrSummaryVM])

    return (
        <div className='side-form-content-left-wrapper'>
            {isLoading && <Loader Indicator="Spinner" size="Medium"/>}
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{CHARGEDETAIL_CONSTANT.SEARCH_CRITERIA}</SidebarTitle>

                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={ibCntrSummaryVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>
                        
            <>
            <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoDeliveryStatus}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoConsVslVoy}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoVslType}
                            {memoEtd}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoHandlingTml}
                        </CriteriaItemContainer>
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={ibCntrSummaryVM.onRemoveAllSearchCriteria} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={(e:any) => {
                    onSearchClicked()
                    }} />
            </SidebarActionBar></>
        </div>        
    )
}