import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useDeliveryChargeSummaryMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useDeliveryChargeSummaryMaintenanceVM";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { memo } from "react";
import { HPHBreadcrumb } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const DeliveryChargeSummaryTitleBar: React.FC = () => {
    const DEL_CHG_SUMMARY_CONSTANT = ChargeConstant.DeliveryChargeSummary;
    const delChgSummaryVM = useDeliveryChargeSummaryMaintenanceVM();

    return <Sidebarheader style={{ width: '100%' }}>
        <HeaderWithBackButton callback={delChgSummaryVM.onCloseClick}>
            <Breadcrumb>
                <HPHBreadcrumb breadcrumbData={[{ title: ChargeConstant.ChargeHeader.CHARGE_PROCESSING }, { title: DEL_CHG_SUMMARY_CONSTANT.DELIVERY_CHARGE_SUMMARY }]} onCurrentClick={() => { }}></HPHBreadcrumb>
            </Breadcrumb>
        </HeaderWithBackButton>
    </Sidebarheader>

}

export default memo(DeliveryChargeSummaryTitleBar);