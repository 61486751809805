import { VesselSummaryHdrEntity } from "domain/entity/ChargeHeader/VesselSummaryHdrEntity";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent } from "helpers/Events";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useVesselSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/VesselSummaryMaintenanceProvider";
import { convertDateToDateTimeForComponent } from "presentation/utils/timeUtil";
import { useCallback } from "react";
import { IconButton } from "veronica-ui-component/dist/component/core";

export const VesselSummaryHeaderFieldPanel = () => {
    
    const VSL_SUMMARY_CONSTANT = ChargeConstant.VesselSummary;
    const CHARGE_CONSTANT = ChargeConstant.Charge;
    const [vslSumState] = useVesselSummaryMaintenanceTracked();
    const [chargeHeaderState] = useChargeHeaderMaintenanceTracked();
    const currentSummaryHeader = vslSumState.currentSummaryHeader;
    const [ anaInfoState ] = useANAInfoTracked();

    const onDetailClick = useCallback(async(vesselSummaryHeader:VesselSummaryHdrEntity) => {
        if (vesselSummaryHeader) {
            customDispatchEvent(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT, 
                E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY, 
                { 
                  data: {
                    vslCode: vesselSummaryHeader.vesselCode,
                    voyCode: vesselSummaryHeader.voyageCode,
                    handlingTml: vesselSummaryHeader.handlingTerminal,
                    chargeType: ["CY"],
                    subChargeType: ["RESH"],
                    redirectType: "RESH_DETAIL",
                    chargeHdrId: chargeHeaderState?.currentChargeHeader?.id,
                    userName: anaInfoState.userName
                  },
                });

        }
    }, [anaInfoState.userName, chargeHeaderState?.currentChargeHeader?.id]);

    // const memoVesselType = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"140px"}}>
    //         <NbisCommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentSummaryHeader.vesselType??""}
    //             fieldLabel={VSL_SUMMARY_CONSTANT.VESSEL_TYPE}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [VSL_SUMMARY_CONSTANT.VESSEL_TYPE, currentSummaryHeader.vesselType]);

    // const memoConsVslVoy = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"300px"}}>
    //         <NbisCommonField
    //             isReadOnly={true}
    //             readOnlyValue={`${currentSummaryHeader.consortiumCode}/
    //                 ${currentSummaryHeader.vesselCode}/
    //                 ${currentSummaryHeader.voyageCode}`
    //             }
    //             fieldLabel={CHARGE_CONSTANT.CO_VSL_VOY}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [CHARGE_CONSTANT.CO_VSL_VOY, currentSummaryHeader.consortiumCode, currentSummaryHeader.vesselCode, currentSummaryHeader.voyageCode]);

    // const memoSOA = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"140px"}}>
    //         <NbisCommonField
    //             isReadOnly={true}
    //             readOnlyValue={chargeHeaderState.currentChargeHeader?.shipOwner??""}
    //             fieldLabel={ChargeConstant.ChargeHeader.SOA}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [chargeHeaderState.currentChargeHeader?.shipOwner]);

    // const memoETD = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"140px"}}>
    //         <DateTimePickerComponent
    //             disabled={true}
    //             date={chargeHeaderState.currentChargeHeader?.etd}
    //             label={ChargeConstant.Charge.ETD}
    //             onDateChange={()=>{}}
    //             />
    //     </div>
    //     </>
    // }, [chargeHeaderState.currentChargeHeader?.etd]);

    // const memoLashing = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"140px"}}>
    //         <NbisCommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentSummaryHeader.isLashing??"N"}
    //             fieldLabel={VSL_SUMMARY_CONSTANT.LASHING}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [VSL_SUMMARY_CONSTANT.LASHING, currentSummaryHeader.isLashing]);

    // const memoGearbox = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"140px"}}>
    //         <NbisCommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentSummaryHeader.gearboxCount?.toString()}
    //             fieldLabel={VSL_SUMMARY_CONSTANT.GEARBOX}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [VSL_SUMMARY_CONSTANT.GEARBOX, currentSummaryHeader.gearboxCount]);

    // const memoIsoHatch = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"140px"}}>
    //         <NbisCommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentSummaryHeader.isohCount?.toString()}
    //             fieldLabel={VSL_SUMMARY_CONSTANT.ISOHATCH}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [VSL_SUMMARY_CONSTANT.ISOHATCH, currentSummaryHeader.isohCount]);

    // const memoLateArrival = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"140px"}}>
    //         <NbisCommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentSummaryHeader.lateArrivalCount?.toString()}
    //             fieldLabel={VSL_SUMMARY_CONSTANT.LATE_ARRIVAL}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [VSL_SUMMARY_CONSTANT.LATE_ARRIVAL, currentSummaryHeader.lateArrivalCount]);

    // const memoShifting = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"140px"}}>
    //         <NbisCommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentSummaryHeader.shiftCount?.toString()}
    //             fieldLabel={VSL_SUMMARY_CONSTANT.SHIFTING_B_B}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [VSL_SUMMARY_CONSTANT.SHIFTING_B_B, currentSummaryHeader.shiftCount]);

    // const memoRestow = useMemo(() => {
    //     return <>        
    //     <div className='flex-row-item' style={{width:"140px"}}>            
    //         <span className="customLabel">{VSL_SUMMARY_CONSTANT.RESTOW_B_S_B}</span>
    //         <div className="customContainer">
    //             <span className="customValue">{currentSummaryHeader.restowCount?.toString()}</span>
    //             {(currentSummaryHeader?.restowCount??0) > 0 &&
    //             <IconButton fileName="Icon-doc" size="small" toolTipPlacement='top' tooltipDisable={false} toolTipText={ChargeConstant.VesselSummary.DETAIL} onClick={()=>onDetailClick(currentSummaryHeader)} />
    //             }
    //         </div>
    //     </div>
    //     </>
    // }, [VSL_SUMMARY_CONSTANT.RESTOW_B_S_B, currentSummaryHeader, onDetailClick]);

    // const memoHandlingTml = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"140px"}}>
    //         <NbisCommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentSummaryHeader.handlingTerminal?.toString()}
    //             fieldLabel={VSL_SUMMARY_CONSTANT.HANDLING_TML}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [VSL_SUMMARY_CONSTANT.HANDLING_TML, currentSummaryHeader.handlingTerminal]);

    return <>
        {/* <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{VSL_SUMMARY_CONSTANT.VESSEL_SUMMARY_HEADER}</SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>
            <div className={'add-edit-form'} style={{ maxHeight:'75vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoConsVslVoy}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoSOA}{memoETD}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoVesselType}{memoLashing}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoGearbox}{memoIsoHatch}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoLateArrival}{memoShifting}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoRestow}{memoHandlingTml}
                        </CriteriaItemContainer>                        
                    </div>                  
                </div>
            </div>
        </div> */}

        <div className="headerInfo">
            <div className="sixColumns">
                <div className={"item"}>
                    <div className={"label"}>
                        {CHARGE_CONSTANT.CO_VSL_VOY}
                    </div>
                    <div className={"value"}>
                        {`${currentSummaryHeader.consortiumCode}/
                        ${currentSummaryHeader.vesselCode}/
                        ${currentSummaryHeader.voyageCode}`}
                    </div>
                </div>
                <div className={"item"}>
                    <div className={"label"}>                    
                        {ChargeConstant.ChargeHeader.SOA}
                    </div>
                    <div className={"value"}>
                        {chargeHeaderState.currentChargeHeader?.shipOwner??""}
                    </div>
                </div>
                <div className={"item"}>
                    <div className={"label"}>
                        {ChargeConstant.Charge.ETD}
                    </div>
                    <div className={"value"}>
                        {convertDateToDateTimeForComponent(chargeHeaderState.currentChargeHeader?.etd)}
                    </div>
                </div>
                <div className={"item"}>
                    <div className={"label"}>
                        {VSL_SUMMARY_CONSTANT.VESSEL_TYPE}
                    </div>
                    <div className={"value"}>
                        {currentSummaryHeader.vesselType??""}
                    </div>
                </div>
                <div className={"item"}>
                    <div className={"label"}>
                        {VSL_SUMMARY_CONSTANT.HANDLING_TML}
                    </div>
                    <div className={"value"}>
                        {currentSummaryHeader.handlingTerminal?.toString()}
                    </div>
                </div>
                <div className={"item"}>
                    <div className={"label"}>
                        {VSL_SUMMARY_CONSTANT.LASHING}
                    </div>
                    <div className={"value"}>
                        {currentSummaryHeader.isLashing??"N"}
                    </div>
                </div>


                <div className={"item"}>
                    <div className={"label"}>
                        {VSL_SUMMARY_CONSTANT.ISOHATCH}
                    </div>
                    <div className={"value"}>
                        {currentSummaryHeader.isohCount?.toString()}
                    </div>
                </div>
                <div className={"item"}>
                    <div className={"label"}>
                        {VSL_SUMMARY_CONSTANT.LATE_ARRIVAL}
                    </div>
                    <div className={"value"}>
                        {currentSummaryHeader.lateArrivalCount?.toString()}
                    </div>
                </div>
                <div className={"item"}>
                    <div className={"label"}>
                        {VSL_SUMMARY_CONSTANT.SHIFTING_B_B}
                    </div>
                    <div className={"value"}>
                        {currentSummaryHeader.shiftCount?.toString()}
                    </div>
                </div>
                <div className={"item"}>
                    <div className={"label"}>
                        {VSL_SUMMARY_CONSTANT.RESTOW_B_S_B}
                    </div>
                    <div className="customContainer" style={{alignItems:"center !important"}}>
                        <span className="value">{currentSummaryHeader.restowCount?.toString()}</span>
                        {(currentSummaryHeader?.restowCount??0) > 0 &&
                        <IconButton fileName="Icon-doc" size="small" toolTipPlacement='top' tooltipDisable={false} toolTipText={ChargeConstant.VesselSummary.DETAIL} onClick={()=>onDetailClick(currentSummaryHeader)} />
                        }
                    </div>
                </div>
                <div className={"item"}>
                    <div className={"label"}>
                        {VSL_SUMMARY_CONSTANT.GEARBOX}
                    </div>
                    <div className={"value"}>
                        {currentSummaryHeader.gearboxCount?.toString()}
                    </div>
                </div>
            </div>
        </div>
    </>
}