import { ChargeHeaderEntity } from "domain/entity/ChargeHeader/ChargeHeaderEntity"
import { InboundContainerSummaryRepository } from "domain/repository/ChargeHeader/InboundContainerSummaryRepo"
import { SearchPreferenceRepository } from "domain/repository/Common/SearchPreferenceRepo"
import _ from "lodash"
import { InboundContainerSummaryEnableSearchCriteria } from "presentation/constant/ChargeHeader/InboundContainerSummary/InboundContainerSummaryEnableSearchCriteria"
import { InboundContainerSummarySearchCriteria } from "presentation/constant/ChargeHeader/InboundContainerSummary/InboundContainerSummarySearchCriteria"
import { ChargeHeaderMaintenanceModel } from "presentation/model/ChargeHeaderMaintenance/ChargeHeaderMaintenanceModel"
import { InboundContainerSummaryMaintenanceModel } from "presentation/model/ChargeHeaderMaintenance/InboundContainerSummaryMaintenanceModel"
import { DropdownProps } from "presentation/model/DropdownProps"
import { Dispatch, SetStateAction } from "react"
import { GroupCheckboxList } from "veronica-ui-component/dist/component/core"
import BaseViewModel from "../BaseViewModel"


interface InboundContainerSummaryVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<InboundContainerSummaryMaintenanceModel>> | ((value: SetStateAction<InboundContainerSummaryMaintenanceModel>) => void),
    ],
    headerDispatch: [
        Dispatch<SetStateAction<ChargeHeaderMaintenanceModel>> | ((value: SetStateAction<ChargeHeaderMaintenanceModel>) => void),
    ]
    searchPreferenceRepo: SearchPreferenceRepository<InboundContainerSummarySearchCriteria,InboundContainerSummaryEnableSearchCriteria>,
    ibCntrSummaryRepo: InboundContainerSummaryRepository,
}

export const InboundContainerSummaryMaintenanceVM = ({ dispatch, headerDispatch, searchPreferenceRepo, ibCntrSummaryRepo}: InboundContainerSummaryVMProps) => {
    const [chargeHeaderDispatch] = headerDispatch;
    const [ibCntrSummaryDispatch] = dispatch;

    const onPageLoad = async (editingHeader: ChargeHeaderEntity | null) => {  
        try {    
            
            editingHeader && await onSearch(editingHeader);                  
              
        } catch (error) {  
  
        }
    };

    const onSearch = async (editingHeader: ChargeHeaderEntity, deliveryStatusList?: string[] | null) => {
        ibCntrSummaryRepo.getInboundContainerSummaryData({
            chargeHdrId: editingHeader.id,
            deliveryStatusList: deliveryStatusList
        }).then((data)=>{
            if (data) {
                ibCntrSummaryDispatch(prevState => ({  
                    ...prevState,
                    vesselType: data[0]?.vesselType,
                    inboundContainerSummaryDataList: data,  
                }));
            } else {
                ibCntrSummaryDispatch(prevState => ({  
                    ...prevState,
                    vesselType: "",
                    inboundContainerSummaryDataList: [],  
                }));
            }
        }).catch((error) => {                
            ibCntrSummaryDispatch(prevState => ({  
                ...prevState,  
                inboundContainerSummaryDataList: [],  
            }));  
  
        }).finally(()=>{
        });
    }



    const onCloseClick = () => {
        chargeHeaderDispatch(prevState => {
            return {
                ...prevState,
                isShowInboundContainerSummary: false,
                isRedirectFromChargeDetail: true,
            }
        }); 
    }

    
    const onShowLoading = () => {
        ibCntrSummaryDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        ibCntrSummaryDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        ibCntrSummaryDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    }

    const onGroupCheckboxChange = (e: (GroupCheckboxList | undefined)[], searchCriteria:InboundContainerSummarySearchCriteria, fieldName:string) => {
        let selectedValue:string[] = [];
        if (e) {
            e.forEach(function(value, index) {
                if (value) {
                    selectedValue.push(value.key);
                }
            });
        }
        searchCriteria = {...searchCriteria, [fieldName]:selectedValue}
        ibCntrSummaryDispatch(prevState => ({
            ...prevState,
            searchCriteria : {
                ...prevState.searchCriteria,
                ...searchCriteria,
            }
        }));
    };

    
    const onRemoveAllSearchCriteria = () => {
        ibCntrSummaryDispatch(prevState => {
            prevState.currentTableRef?.current?.api.setServerSideDatasource({
                getRows: (params: any) => {
                    params.success({
                        rowData: [],
                        rowCount: 0
                    })
                },
            })
            
            return {
                ...prevState, searchCounter: 0, searchCriteria: {...prevState.searchCriteria,deliveryStatusList:[] }
            }
        })
    }

    const onSearchClick = () => {
        ibCntrSummaryDispatch(prevState => ({
            ...prevState,
            isShowCriteriaPanel: !prevState.isShowCriteriaPanel,
        }))
    };

    return {
        onPageLoad: onPageLoad,
        onCloseClick: onCloseClick,
        onSearch: onSearch,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onRemoveAllSearchCriteria: onRemoveAllSearchCriteria,
        onSearchClick: onSearchClick,
        onGroupCheckboxChange: onGroupCheckboxChange,
    }
}

