import { INITIAL_VESSEL_SUMMARY_HEADER_COL_DEF, transferRowData } from "presentation/constant/ChargeHeader/VesselSummary/VesselSummaryColumnDefinition";
import { useVesselSummaryMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useVesselSummaryMaintenanceVM";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useVesselSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/VesselSummaryMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useCallback, useMemo } from "react";


export const VesselSummaryTablePanel: React.FC = () => {
    const [vslSumMainState] = useVesselSummaryMaintenanceTracked();
    const [chargeHeaderState] = useChargeHeaderMaintenanceTracked();
    const vslSummaryVM = useVesselSummaryMaintenanceVM();

    const tbBtns = useMemo(() => {
        let btns = [];
        btns.push({
            id: 'onSummary',
            icon: 'Icon-doc',
            title: 'Terminal Departure Report'
        });
        return btns;
    }, [])

    const onSummary = useCallback(() => {
        vslSummaryVM.toggleTdrPanel(vslSumMainState.isShowTdrPanel, chargeHeaderState.currentChargeHeader)
    }, [vslSumMainState.isShowTdrPanel, vslSummaryVM, chargeHeaderState?.currentChargeHeader])

    const memoGroupTable = useMemo(() => {
        return (
            <NbisTable
                id={'Vessel Summary Table'}
                isNewColumnSetting={true}
                // headerLabel={VSL_SUMMARY_CONSTANT.VESSEL_SUMMARY}
                columns={INITIAL_VESSEL_SUMMARY_HEADER_COL_DEF}
                data={transferRowData(vslSumMainState.vesselSummaryData?.cntrInfoList, (chargeHeaderState?.currentChargeHeader?.id ?? 0))}
                headerActionButtons={tbBtns}
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                isRowHighligted={true}
                gridHeight="customHeight"
                customHeight="calc(79vh)"
                onSummary={onSummary}
            />
        );
    }, [vslSumMainState.vesselSummaryData?.cntrInfoList, chargeHeaderState?.currentChargeHeader?.id, tbBtns, onSummary])

    return <TableWrapper>{memoGroupTable}</TableWrapper>
}


