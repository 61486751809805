import type { CustomCellRendererProps } from "ag-grid-react";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent } from "helpers/Events";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithColorAndAlertIcon } from "presentation/view/components/TableWrapper/TableCellRender";

const DEL_CHARGE_SUMMARY_CONSTANT = ChargeConstant.DeliveryChargeSummary;

let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];

export const INITIAL_DELIVERY_CHARGE_SUMMARY_HEADER_COL_DEF: any[] = [
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.OPS_LINE,
        field: 'owner1',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.HANDLING_TML,
        field: 'handlingTerminal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.CONSORTIUM_CODE,
        field: 'consortiumCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.VESSEL_CODE,
        field: 'vesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.VOYAGE_CODE,
        field: 'voyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.VESSEL_NAME,
        field: 'vesselName',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 300,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.ETA,
        field: 'eta',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
        dataType: "dateTime",
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.ETD,
        field: 'etd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
        dataType: "dateTime",
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.SOA,
        field: 'soa',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.SERVICE_CODE,
        field: 'serviceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.BILLING_CYCLE,
        field: 'billingCycle',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.BILLING_CYCLE_CODE,
        field: 'billingCycleCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.DISCHARGE,
        field: 'dischargeCount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.END_VISIT_IND,
        field: 'visitCount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.BILLED,
        field: 'billedCount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.TO_BE_BILLED,
        field: 'toBeBilledCount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.NON_BILL_ABLE,
        field: 'nonBillableCount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.NOT_DELIVERY,
        field: 'notDeliveryCount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DEL_CHARGE_SUMMARY_CONSTANT.DIFFERENT,
        field: 'differentCount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    cellRenderers['differentCount'] = cellRenderWithColorAndAlertIcon;
    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
}
);

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    externalFnctions['differentCount'] = calculateDifferentIcon;
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}

export const calculateDifferentIcon = (fieldName: string, row: any) => {
    let result: { [key: string]: any } = {};
    if (fieldName && row) {
        const className = fieldName + "Class";
        const toolTipName = fieldName + "Tooltip";
        const listenerName = fieldName + "Listener";
        const fieldValue: number = (row[fieldName] as unknown as number);
        result[fieldName] = fieldValue;

        if (fieldValue && fieldValue > 0) {
            result[className] = "red";
            result[toolTipName] = ChargeConstant.DeliveryChargeSummary.SHOW_DIFFERENCE;
            result[listenerName] = () => {
                customDispatchEvent(E_Type_Of_Event.DELIVERY_SUMMARY_REDIRECT_EVENT,
                    E_Custom_Dispatch_Event.REDIRECT_CHARGE_ITEM_FROM_DELIVERY_SUMMARY,
                    { data: row }
                )
            }
        }
    }
    return result;
}

