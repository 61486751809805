import type { CustomCellRendererProps } from "ag-grid-react";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent } from "helpers/Events";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithNumberColorAndDocIcon } from "presentation/view/components/TableWrapper/TableCellRender";

const VSL_SUMMARY_CONSTANT = ChargeConstant.VesselSummary;

export const INITIAL_VESSEL_SUMMARY_HEADER_COL_DEF: any[] = [
    {
        headerName: VSL_SUMMARY_CONSTANT.BILL_TO_COMPANY,
        field: 'cntrOwner',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.SIZE,
        field: 'sizeStr',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.SIZE_UNIT,
        field: 'sizeUnit',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.DISCHARGE_LOCAL,
        field: 'dischargeLocal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.DISCHARGE_TRANSHIPMENT,
        field: 'dischargeTranshipment',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.D1_TOTAL,
        field: 'dischargeSubtotal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.D1_CY_OOG,
        field: 'd1CyOog',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.D1_CY_IMO,
        field: 'd1CyImo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.D1_CY_BM,
        field: 'd1CyBm',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.D1_CY_BS,
        field: 'd1CyBs',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.L2_LOCAL,
        field: 'loadingLocal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.L2_TS,
        field: 'loadingTranshipment',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.L2_TOTAL,
        field: 'loadingSubtotal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.L2_CY_OOG,
        field: 'l2CyOog',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.L2_CY_IMO,
        field: 'l2CyImo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.L2_CY_BM,
        field: 'l2CyBm',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.L2_CY_BS,
        field: 'l2CyBs',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.TOTAL,
        field: 'total',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.PERCENT,
        field: 'percent',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.D1_YH_OOG,
        field: 'd1YhOog',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.D1_YH_IMO,
        field: 'd1YhImo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.D1_YH_BM,
        field: 'd1YhBm',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: VSL_SUMMARY_CONSTANT.D1_YH_BS,
        field: 'd1YhBs',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    cellRenderers['dischargeLocal'] = cellRenderWithNumberColorAndDocIcon;
    cellRenderers['dischargeTranshipment'] = cellRenderWithNumberColorAndDocIcon;
    cellRenderers['loadingLocal'] = cellRenderWithNumberColorAndDocIcon;
    cellRenderers['loadingTranshipment'] = cellRenderWithNumberColorAndDocIcon;
    cellRenderers['d1CyOog'] = cellRenderWithNumberColorAndDocIcon;
    cellRenderers['d1CyImo'] = cellRenderWithNumberColorAndDocIcon;
    cellRenderers['d1CyBm'] = cellRenderWithNumberColorAndDocIcon;
    cellRenderers['d1CyBs'] = cellRenderWithNumberColorAndDocIcon;
    cellRenderers['l2CyOog'] = cellRenderWithNumberColorAndDocIcon;
    cellRenderers['l2CyImo'] = cellRenderWithNumberColorAndDocIcon;
    cellRenderers['l2CyBm'] = cellRenderWithNumberColorAndDocIcon;
    cellRenderers['l2CyBs'] = cellRenderWithNumberColorAndDocIcon;
    cellRenderers['d1YhOog'] = cellRenderWithNumberColorAndDocIcon;
    cellRenderers['d1YhImo'] = cellRenderWithNumberColorAndDocIcon;
    cellRenderers['d1YhBm'] = cellRenderWithNumberColorAndDocIcon;
    cellRenderers['d1YhBs'] = cellRenderWithNumberColorAndDocIcon;

    return defineColumn(col, index, [], [], [], cellRenderers);
});

export const transferRowData = (data: any[], chargeHdrId: number | null) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    let newData = data?.map(row => ({
        ...row,
        chargeHdrId: chargeHdrId
    }));

    externalFnctions['dischargeLocal'] = calculatFieldIcon;
    externalFnctions['dischargeTranshipment'] = calculatFieldIcon;
    externalFnctions['loadingLocal'] = calculatFieldIcon;
    externalFnctions['loadingTranshipment'] = calculatFieldIcon;
    externalFnctions['d1CyOog'] = calculatFieldIcon;
    externalFnctions['d1CyImo'] = calculatFieldIcon;
    externalFnctions['d1CyBm'] = calculatFieldIcon;
    externalFnctions['d1CyBs'] = calculatFieldIcon;
    externalFnctions['l2CyOog'] = calculatFieldIcon;
    externalFnctions['l2CyImo'] = calculatFieldIcon;
    externalFnctions['l2CyBm'] = calculatFieldIcon;
    externalFnctions['l2CyBs'] = calculatFieldIcon;
    externalFnctions['d1YhOog'] = calculatFieldIcon;
    externalFnctions['d1YhImo'] = calculatFieldIcon;
    externalFnctions['d1YhBm'] = calculatFieldIcon;
    externalFnctions['d1YhBs'] = calculatFieldIcon;

    return transferRowDataInternal(newData, [], [], [], externalFnctions);
}

export const calculatFieldIcon = (fieldName: string, row: any) => {
    let result: { [key: string]: any } = {};
    if (fieldName && row) {
        const className = fieldName + "Class";
        const toolTipName = fieldName + "Tooltip";
        const listenerName = fieldName + "Listener";
        const fieldValue: number = (row[fieldName] as unknown as number);
        result[fieldName] = fieldValue;

        if (fieldValue && fieldValue > 0) {
            result[className] = "";
            result[toolTipName] = ChargeConstant.VesselSummary.DETAIL;
            result[listenerName] = () => {
                customDispatchEvent(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT,
                    E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY,
                    {
                        data:
                        {
                            ...row,
                            redirectType: "Summary_Column",
                            fieldName: fieldName
                        }
                    }
                )
            }
        }
    }
    return result;
}



