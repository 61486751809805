import { EMPTY_SEARCH_MODE_ENTITY, SearchModeEntity } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { useChargeHeaderMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useChargeHeaderMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useEffect, useMemo, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import { ChargeHeaderSearchCriteriaEditVoyagePanel } from "./ChargeHeaderSearchCriteriaEditPanel/ChargeHeaderSearchCriteriaEditVoyagePanel";

export const ChargeHeaderSearchCriteriaPanel = () => {
    const [chargeDataSearchState] = useChargeHeaderMaintenanceTracked();
    const messageBarVM = useMessageBarVM();
    const chargeDataSearchVM = useChargeHeaderMaintenanceVM();
    const searchCriteria = chargeDataSearchState.searchCriteria;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const searchModeEntity: SearchModeEntity = {
        ...EMPTY_SEARCH_MODE_ENTITY,                
    };
    const CHARGEDETAIL_CONSTANT = ChargeDetailConstant.Charge;
    const [anaInfoState] = useANAInfoTracked();

    const memoChargeHeaderSearchCriteriaEditVoyagePanel = useMemo(() => {
        return <ChargeHeaderSearchCriteriaEditVoyagePanel/>
    }, []);

    const handleClearClicked = () => {
        chargeDataSearchVM.onRemoveAllSearchCriteria(anaInfoState.userName);
    }
    
    useEffect(() => {
        let timer: any = null;
        const cb = () => {
            const comField: any = document.querySelector('.im-charge-data-search-criteria-content input[name="co"]');
            if (comField) {
                comField.focus();
                timer && clearInterval(timer);
            }
        };

        timer = setInterval(cb, 0);
    }, []);

    const handleSearch =  (e:any) => {
        if(chargeDataSearchVM.validateSearchCriteria(searchCriteria)){
            messageBarVM.showWarining("Fill in at least one of the search criteria.");
            return;
        }
        setIsLoading(true);

        chargeDataSearchVM.onSearch(searchCriteria, searchModeEntity).then(data => {
            if (data && data["validateSearchCriteria"]) {
                messageBarVM.showError(data["validateSearchCriteria"]?.toString());
            }else{
                chargeDataSearchVM.onSwithTableStructure();
                localStorage.setItem("ChargeHeaderSearchCriteria", JSON.stringify(searchCriteria));
            }
            setIsLoading(false);
        }).catch((error)=>{
            messageBarVM.showError(error.message);
            setIsLoading(false);
        });

    }

    const closeButtonClicked = () => {
        chargeDataSearchVM.onSearchClick();
    }

    return <div className='side-form-content-left-wrapper' style={{padding:"20px 20px 20px 0px"}}>
        {isLoading && <Loader Indicator="Spinner" size="Medium"/>}
        <div className={'flex-row'}>
            <div className={'flex-row-item flex-row-item-full-width'}>
                <Sidebarheader>
                    <SidebarTitle>{CHARGEDETAIL_CONSTANT.SEARCH_CRITERIA}</SidebarTitle>

                    <SidebarActionCross>
                        <IconButton
                            fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={closeButtonClicked} />
                    </SidebarActionCross>
                </Sidebarheader>                
            </div>
        </div>
                    
        <>
        <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    
                    {/* Stack Form Start */}
                    {memoChargeHeaderSearchCriteriaEditVoyagePanel}
                </div>                  
            </div>
        </div>
        <SidebarActionBar>
            <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={handleClearClicked} />
            <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={handleSearch} />
        </SidebarActionBar></>
    </div>
};
