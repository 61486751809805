import { EMPTY_VESSEL_VOYAGE_MASTER_SEARCH_CRITERIA, VesselVoyageMasterSearchCriteria } from "domain/entity/ChargeDetailMaintenance/VesselVoyageMasterSearchCriteria"
import { ChargeHeaderEntity } from "domain/entity/ChargeHeader/ChargeHeaderEntity"
import { VesselSummaryEntity } from "domain/entity/ChargeHeader/VesselSummaryEntity"
import { VesselSummaryHdrEntity } from "domain/entity/ChargeHeader/VesselSummaryHdrEntity"
import { EMPTY_VESSEL_SUMMARY_TDR_ENTITY, VesselSummaryTdrEntity } from "domain/entity/ChargeHeader/VesselSummaryTdrEntity"
import { VesselVoyageMasterRepository } from "domain/repository/ChargeDetailMaintenance/VesselVoyageMasterRepo"
import { VesselSummaryRepository } from "domain/repository/ChargeHeader/VesselSummaryRepo"
import { VesselSummaryTdrRepository } from "domain/repository/ChargeHeader/VesselSummaryTdrRepo"
import { SearchPreferenceRepository } from "domain/repository/Common/SearchPreferenceRepo"
import { VesselSummaryEnableSearchCriteria } from "presentation/constant/ChargeHeader/VesselSummary/VesselSummaryEnableSearchCriteria"
import { VesselSummarySearchCriteria } from "presentation/constant/ChargeHeader/VesselSummary/VesselSummarySearchCriteria"
import { ChargeHeaderMaintenanceModel } from "presentation/model/ChargeHeaderMaintenance/ChargeHeaderMaintenanceModel"
import { VesselSummaryMaintenanceModel } from "presentation/model/ChargeHeaderMaintenance/VesselSummaryMaintenanceModel"
import { ChangeEvent, Dispatch, SetStateAction } from "react"
import BaseViewModel from "../BaseViewModel"


interface VesselSummaryMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<VesselSummaryMaintenanceModel>> | ((value: SetStateAction<VesselSummaryMaintenanceModel>) => void),
    ],
    headerDispatch: [
        Dispatch<SetStateAction<ChargeHeaderMaintenanceModel>> | ((value: SetStateAction<ChargeHeaderMaintenanceModel>) => void),
    ]
    searchPreferenceRepo: SearchPreferenceRepository<VesselSummarySearchCriteria,VesselSummaryEnableSearchCriteria>,
    vslSummaryRepo: VesselSummaryRepository,
    vslSummaryTdrRepo: VesselSummaryTdrRepository,
    vesselVoyageMasterRepo: VesselVoyageMasterRepository
}

export const VesselSummaryMaintenanceVM = ({ dispatch, headerDispatch, searchPreferenceRepo, vslSummaryRepo, vslSummaryTdrRepo, vesselVoyageMasterRepo}: VesselSummaryMaintenanceVMProps) => {
    const [chargeHeaderDispatch] = headerDispatch;
    const [vslSummaryDispatch] = dispatch;

    const onPageLoad = async (editingHeader: ChargeHeaderEntity | null) => {  
        try {
            const searchMasterCriteria:VesselVoyageMasterSearchCriteria = EMPTY_VESSEL_VOYAGE_MASTER_SEARCH_CRITERIA;
            searchMasterCriteria.consortiumCode = editingHeader?.consortiumCode;
            searchMasterCriteria.voyageCode = editingHeader?.voyageCode;
            searchMasterCriteria.vesselCode = editingHeader?.vesselCode;
            if(editingHeader){                
                onShowLoading();
                Promise.allSettled([
                    onSearchSummaryHdr(editingHeader).then((data) => {
                        vslSummaryDispatch(prevState => ({  
                            ...prevState,  
                            currentSummaryHeader: data,  
                        }));
                    }),
                    onSearch(editingHeader).then(data => {
                        vslSummaryDispatch(prevState => ({  
                            ...prevState,  
                            vesselSummaryData: data,  
                        }));
                    }),
                    vesselVoyageMasterRepo.getVesselVoyageMasterByCoVslVoy(searchMasterCriteria).then(data => {
                        vslSummaryDispatch(prevState => ({  
                            ...prevState,  
                            currentSummaryHeader: {
                                ...prevState.currentSummaryHeader,
                                vesselType: data.vesselType??""
                            },  
                        }));
                    })
                ]).finally(() => {
                    onHideLoading();
                });                
            }
             
        } catch (error) {  
  
        } finally{            
        }
    };

    const onSearch = async (editingHeader: ChargeHeaderEntity): Promise<VesselSummaryEntity> => {
        return await vslSummaryRepo.getVesselSummaryData({
            vesselCode: editingHeader.vesselCode,
            voyageCode: editingHeader.voyageCode,
            handlingTerminal: editingHeader.handlingTerminal});
    }

    const onSearchSummaryHdr = async (editingHeader: ChargeHeaderEntity): Promise<VesselSummaryHdrEntity> => {
        return await vslSummaryRepo.getVesselSummaryHdr({
            vesselCode: editingHeader.vesselCode,
            voyageCode: editingHeader.voyageCode,
            handlingTerminal: editingHeader.handlingTerminal});
    }


    const onCloseClick = () => {
        chargeHeaderDispatch(prevState => {
            return {
                ...prevState,
                isShowVesselSummary: false,
                isRedirectFromChargeDetail: true,
            }
        }); 
    }

    
    const onShowLoading = () => {
        vslSummaryDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        vslSummaryDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }


    
    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        vslSummaryDispatch(prevState => ({            
            ...prevState,
            tdrData: {
                ...prevState.tdrData,
                [fieldName]: e.target.value,
            }
        }))
    };

    const onTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>, fieldName: string) => {
        vslSummaryDispatch(prevState => ({
            ...prevState,
            tdrData: {
                ...prevState.tdrData,
                [fieldName]: e.target.value,
            }
        }))
    };

    const toggleTdrPanel = (isShowTdrPanel:boolean, editingHeader: ChargeHeaderEntity | null) => {
        if(!isShowTdrPanel){
            editingHeader && onTdrSearch(editingHeader.id);
        }else{
            vslSummaryDispatch(prevState => {         
                return {
                    ...prevState,
                    isShowTdrPanel: !prevState.isShowTdrPanel,
                    isShowSummaryHeaderFieldPanel: !prevState.isShowSummaryHeaderFieldPanel,
                }
            }); 
        }
        
    }    

    const onTdrSearch = (editingHdrId: number|null) => {
        onShowLoading();
        vslSummaryTdrRepo.getVesselSummaryTdrData(editingHdrId).then((data) => {
            vslSummaryDispatch(prevState => {
                return {
                    ...prevState,
                    isShowTdrPanel: true,
                    isShowSummaryHeaderFieldPanel: false,
                    tdrData: data??EMPTY_VESSEL_SUMMARY_TDR_ENTITY,
                }
            })
        }).catch((error) => {

        }).finally(()=>{
            onHideLoading();
        })
    }

    const onApply = (editingHeader: ChargeHeaderEntity, editingTdrData: VesselSummaryTdrEntity) => {
        onShowLoading();
        if(!editingTdrData.id){
            return vslSummaryTdrRepo.saveVesselSummaryTdrData({
                ...editingTdrData,
                chargeHdrId: editingHeader.id
            }).then(() => {                
                onTdrSearch(editingHeader.id)
            }).finally(()=>{
                onHideLoading();
            })
        }else{
            return  vslSummaryTdrRepo.replaceVesselSummaryTdrData({
                ...editingTdrData,
                chargeHdrId: editingHeader.id
            }).then(()=>{
                onTdrSearch(editingHeader.id)
            }).finally(()=>{
                onHideLoading();
            })
        }
        
    }

    const onClose = () => {
        vslSummaryDispatch(prevState => {
            return {
                ...prevState,
                isShowTdrPanel: false,
                isShowSummaryHeaderFieldPanel: true,
            }
        }); 
    }

    return {
        onPageLoad: onPageLoad,
        onCloseClick: onCloseClick,
        onSearch: onSearch,
        onSearchSummaryHdr: onSearchSummaryHdr,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        toggleTdrPanel: toggleTdrPanel,
        onInputTextChange: onInputTextChange,
        onTextAreaChange: onTextAreaChange,
        onTdrSearch: onTdrSearch,
        onApply: onApply,
        onClose: onClose,
    }
}

