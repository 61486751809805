import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useInboundContainerSummaryMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useInboundContainerSummaryMaintenanceVM";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { memo } from "react";
import { HPHBreadcrumb } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const InboundContainerSummaryTitleBar: React.FC = () => {
    const IB_CNTR_SUMMARY_CONSTANT = ChargeConstant.InboundContainerSummary;
    const vslSummaryVM = useInboundContainerSummaryMaintenanceVM();

    return <Sidebarheader style={{ width: '100%' }}>
        <HeaderWithBackButton callback={vslSummaryVM.onCloseClick}>
            <Breadcrumb>
                <HPHBreadcrumb breadcrumbData={[{ title: ChargeConstant.ChargeHeader.CHARGE_PROCESSING }, { title: IB_CNTR_SUMMARY_CONSTANT.INBOUND_CONTAINER_SUMMARY }]} onCurrentClick={() => { }}></HPHBreadcrumb>
            </Breadcrumb>
        </HeaderWithBackButton>
    </Sidebarheader>

}

export default memo(InboundContainerSummaryTitleBar);